import './App.css';
import bird from "./images/bird.png";
import riser from "./images/riser.png";
import base from "./images/base.png";

import Demo from "./demo.js";

import { motion, useScroll, useTransform } from "framer-motion";

import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import Parser from 'html-react-parser';

import { useRef } from "react";

hljs.registerLanguage('python', python);
hljs.addPlugin({
  "after:highlight": (params) => {
    const openTags = [];
    
    params.value = params.value.replace(/(<span [^>]+>)|(<\/span>)|(\n)/g, match => {
      if (match === "\n") {
        return "</span>".repeat(openTags.length) + "\n" + openTags.join("");
      }
      
      if (match === "</span>") {
        openTags.pop();
      } else {
        openTags.push(match);
      }
      
      return match;
    });
  },
});



function App() {
    const { scrollYProgress } = useScroll();
    const riserSpring = useTransform(scrollYProgress, [0,1], [0,300]);
    const birdSpring = useTransform(scrollYProgress, [0,1], [0,-600]);
    const birdSizeSpring = useTransform(scrollYProgress, [0,0.3], [1,0]);
    const content = useRef(null);

    return (
        <div style={{"width": "100vw"}}>
            <header>
                <div className="header-container" style={{height: "50px", background: "rgb(252, 252, 252)"}}>
                    <div className="heading"><a href="/">#!/Simon</a></div>
                    <div className="heading-right">
                        {/* <a href="" className="heading-link">Blog</a> */}
                        <a href="https://github.com/Shabang-Systems/simon" className="heading-link">Repo</a>
                        <a href="/about" className="heading-link">About</a>
                    </div>
                </div>
            </header>

            <div className="box">
                <div className="text">
                    <div className="flagship">
                        <div className="center-callout">
                            <span className="callout">AI search:</span>
                            <div className="boxalign">
                                <span className="callout callout-bold">your data + 10 lines of code.</span>
                                <div className="center-buttons">
                                    <a href="/start" className="center-button big">Quick Start</a>, or<a className="center-button"
                                                                                                         style={{
                                                                                                             textDecoration: "underline",
                                                                                                             textDecorationThickness: "1px",
                                                                                                             paddingLeft: "8px"
                                                                                                         }}
                                                                                                         onClick={() => content.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="center-subtitle"> */}
                {/*     <div className="install-codeblock"> */}
                {/*         $ pip install simon-search */}
                {/*     </div> */}
                {/* </div> */}
                <div className="hero">
                    <div className="centerstack" style={{transform: "translateY(60px)"}}>
                        <motion.div  style={{y: birdSpring}} className="heroimg hero-bird">
                            <motion.img src={bird} style={{transform: "translate(-250px, -100px)"}} draggable="false"/>
                        </motion.div>
                        <motion.div style={{y: riserSpring}} className="heroimg hero-riser">
                            <motion.img src={riser}/>
                        </motion.div>
                        <motion.div  style={{transform: "translateY(40px)"}} className="heroimg hero-base">
                            <motion.img src={base}  draggable="false"/>
                        </motion.div>
                    </div>
                </div>
            </div>

            <motion.div style={{"padding": "40px"}} className="content" ref={content}>
                <h1 className="explain-heading">How?</h1>
                <p className="explain-text">As promised, 10 lines of code:</p>
                <div className="border border-slate-200 hidebar" style={{fontFamily: "IBM Plex Mono, monospace", padding: "20px", whiteSpace: "nowrap", width: "100%", overflowX: "scroll", overflowY: "hidden",  borderRadius: "3px", marginTop:"15px"}}>
                    <div>{Parser(hljs.highlight('import simon', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('context = simon.create_context("wikisearch", openai_api_key="sk-mykey", db_config={**postgres_creds})', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('simon.setup(context) # do this once per *database*', { language: 'python' }).value)}</div>
                    <br />
                    <div>{Parser(hljs.highlight('# store some articles', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('ds = simon.Datastore(context)', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('articles = ["https://en.wikipedia.org/wiki/Chicken", ...]', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('for i in articles:', { language: 'python' }).value)}</div>
                    <div style={{paddingLeft: "20px"}}>{Parser(hljs.highlight('ds.store_remote(i)', { language: 'python' }).value)}</div>
                    <br />
                    <div>{Parser(hljs.highlight('# search the articles', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('s = simon.Search(context)', { language: 'python' }).value)}</div>
                    <div>{Parser(hljs.highlight('json_results = s.query("what\'s a chicken")', { language: 'python' }).value)}</div>
                </div>
                <h1 className="explain-heading">Epic!</h1>
                <p className="explain-text">Ready to make your data work for you? <code style={{padding: "0 10px"}}>pip install simon-search</code> and let's rock: <a href="/start" className="qsg">Quick Start Guide</a></p>
                <p className="explain-text">Have a question? Want to contribute? <a style={{
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                    color: "var(--darkergreen)"
                }} href="https://github.com/Shabang-Systems/simon/discussions">Reach out on GitHub</a>! We are eager to help you get semantic searching 🙌</p>
            </motion.div>
            <div>
                <div className="footer p-3" style={{fontSize: "9px"}}>
                    <div style={{color: "var(--grey)", marginBottom: "10px"}}>
                        <code style={{opacity: 0.8}}>© 2023 Shabang Systems, LLC and various open source contributors. Built with ❤️ and <a href="https://www.jklsnt.com/">🥗</a> in the SF Bay Area.</code>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default App;
